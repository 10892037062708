import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container } from 'react-bootstrap';
import './RegistrationForm.css'; // Импортируйте стили
import { toast } from 'react-toastify';

const RegistrationForm = ({ onSuccess }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    level: '',
    workplace: '',
    position: '',
    consent: false, // Добавляем состояние для согласия
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, consent: e.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.consent) {
      alert('Пожалуйста, примите условия пользовательского соглашения.');
      return;
    }
    axios.post('https://api.doctorenglish.uz/register', formData)
      .then(response => {
        toast.success(response.data.message);
        onSuccess(formData);
      })
      .catch(error => console.error(error));
  };

  return (
    <div className="page-container">
      <Container className="form-container">
        <Form onSubmit={handleSubmit}>
          <h2 className="form-title-main">Запишитесь на консультацию:</h2>
          <h2 className="form-title-secondary">А так же определите свой уровень в тесте по английскому языку онлайн</h2>
          <Form.Group controlId="formName" className="form-group">
            <Form.Label className="form-label"></Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Имя"
              className="form-control modern-input"
              required
            />
          </Form.Group>
          <Form.Group controlId="formPhone" className="form-group">
            <Form.Label className="form-label"></Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="+998(__)___-__-__"
              className="form-control modern-input"
              required
            />
          </Form.Group>
          <Form.Group controlId="formEmail" className="form-group">
            <Form.Label className="form-label"></Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="ваш@email.com"
              className="form-control modern-input"
            />
          </Form.Group>
          <Form.Group controlId="formLevel" className="form-group">
            <Form.Label className="form-label"></Form.Label>
            <Form.Control
              as="select"
              name="level"
              value={formData.level}
              onChange={handleChange}
              className="select-control modern-input"
              required
            >
              <option value="">Выберите уровень</option>
              <option value="beginner">Beginner + Elementary</option>
              <option value="pre_intermediate">Pre-Intermediate</option>
              <option value="intermediate">Intermediate</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formWorkplace" className="form-group">
            <Form.Label className="form-label"></Form.Label>
            <Form.Control
              type="text"
              name="workplace"
              value={formData.workplace}
              onChange={handleChange}
              placeholder="Место работы..."
              className="form-control modern-input"
              required
            />
          </Form.Group>
          <Form.Group controlId="formPosition" className="form-group">
            <Form.Label className="form-label"></Form.Label>
            <Form.Control
              type="text"
              name="position"
              value={formData.position}
              onChange={handleChange}
              placeholder="Ваша должность..."
              className="form-control modern-input"
              required
            />
          </Form.Group>
          <Form.Group controlId="formConsent" className="form-group">
            <Form.Check
              type="checkbox"
              name="consent"
              checked={formData.consent}
              onChange={handleCheckboxChange}
              label={
                <>
                  Соглашаюсь на обработку персональных данных в соответствии с{' '}
                  <a href="https://drive.google.com/file/d/1QvlujGuJ6SXU6iB6_oLwE9YZeYTh6gC9/view?usp=sharing" target="_blank" rel="noopener noreferrer">условиями пользовательского соглашения</a>.
                </>
              }
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="btn-submit">
            Хочу на консультацию
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default RegistrationForm;
